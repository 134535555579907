import React from "react";
import classNames from "classnames/bind";

import styles from "./carer-availability.module.scss";
import workingAreaHelper from "../helpers/carer-working-area";

import Button from "../system/button";

const staticMbxClient = require("@mapbox/mapbox-sdk/services/static");
const geocodingMbxClient = require("@mapbox/mapbox-sdk/services/geocoding");

let cxStyles = classNames.bind(styles);

export default ({ carer, availabilities }) => {
  const dayStyle = (isWeekday, active) =>
    cxStyles({
      base: true,
      weekday: isWeekday,
      weekend: !isWeekday,
      active: active,
    });

  const location = carer.memberLocation || "Wellington";

  const mapboxStaticClient = staticMbxClient({
    accessToken:
      "pk.eyJ1IjoibHVrZWNyZWF0ZXMiLCJhIjoiY2o4bzhuOHppMDBvZDMzcno0bTQxeTF1ZyJ9.MJLzgEhcxCDHvm04YHzB6g",
  });

  const mapboxGeoClient = geocodingMbxClient({
    accessToken:
      "pk.eyJ1IjoibHVrZWNyZWF0ZXMiLCJhIjoiY2o4bzhuOHppMDBvZDMzcno0bTQxeTF1ZyJ9.MJLzgEhcxCDHvm04YHzB6g",
  });

  mapboxGeoClient
    .forwardGeocode({
      query: `${location}, England`,
      proximity: [-3.2243, 50.9755],
      limit: 1,
    })
    .send()
    .then((response) => {
      const match = response.body;
      // console.log("geocode response", match);
    });

  var createGeoJSONCircle = function (center, radiusInKm, points) {
    if (!points) points = 64;

    var coords = {
      latitude: center[1],
      longitude: center[0],
    };

    var km = radiusInKm;

    var ret = [];
    var distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
    var distanceY = km / 110.574;

    var theta, x, y;
    for (var i = 0; i < points; i++) {
      theta = (i / points) * (2 * Math.PI);
      x = distanceX * Math.cos(theta);
      y = distanceY * Math.sin(theta);

      ret.push([coords.longitude + x, coords.latitude + y]);
    }
    ret.push(ret[0]);

    return {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [ret],
            },
          },
        ],
      },
    };
  };

  const radiusOverlay = createGeoJSONCircle([-3.22746021, 50.97859955], 2);
  // console.log(radiusOverlay);

  // mapboxStaticClient
  //   .getStaticImage({
  //     ownerId: "mapbox",
  //     styleId: "streets-v11",
  //     width: 200,
  //     height: 300,
  //     position: {
  //       coordinates: [-3.22746021, 50.97859955],
  //       zoom: 8,
  //     },
  //     overlays: [
  //       // Simple markers.
  //       {
  //         marker: {
  //           coordinates: [12.2, 12.8],
  //         },
  //       },
  //       {
  //         geoJson: {
  //           type: "geojson",
  //           data: {
  //             type: "FeatureCollection",
  //             features: [
  //               {
  //                 type: "Feature",
  //                 geometry: {
  //                   type: "Polygon",
  //                   coordinates: [
  //                     [
  //                       [-3.198924758092204, 50.97859955],
  //                       [-3.1990621640926724, 50.98037242862119],
  //                       [-3.199473058799179, 50.982128233452094],
  //                       [-3.200153485071083, 50.983850055132386],
  //                       [-3.201096890031427, 50.98552131157804],
  //                       [-3.202294188174725, 50.987125907675875],
  //                       [-3.2037338488653466, 50.988648390288304],
  //                       [-3.205402007383844, 50.99007409707551],
  //                       [-3.2072825984517745, 50.991389297701744],
  //                       [-3.2093575109491193, 50.99258132606603],
  //                       [-3.211606762334266, 50.99363870228358],
  //                       [-3.2140086910868098, 50.99455124324341],
  //                       [-3.2165401653198353, 50.995310160677214],
  //                       [-3.219176805552635, 50.995908145795255],
  //                       [-3.221893219498432, 50.99633943967394],
  //                       [-3.224663246605986, 50.996599888717455],
  //                       [-3.22746021, 50.99668698465914],
  //                       [-3.2302571733940137, 50.996599888717455],
  //                       [-3.2330272005015677, 50.99633943967394],
  //                       [-3.235743614447365, 50.995908145795255],
  //                       [-3.2383802546801643, 50.995310160677214],
  //                       [-3.24091172891319, 50.99455124324341],
  //                       [-3.2433136576657335, 50.99363870228358],
  //                       [-3.2455629090508804, 50.99258132606603],
  //                       [-3.247637821548225, 50.991389297701744],
  //                       [-3.2495184126161556, 50.99007409707551],
  //                       [-3.251186571134653, 50.988648390288304],
  //                       [-3.2526262318252748, 50.987125907675875],
  //                       [-3.2538235299685727, 50.98552131157804],
  //                       [-3.2547669349289166, 50.983850055132386],
  //                       [-3.2554473612008206, 50.982128233452094],
  //                       [-3.2558582559073272, 50.98037242862119],
  //                       [-3.2559956619077957, 50.97859955],
  //                       [-3.2558582559073272, 50.97682667137881],
  //                       [-3.2554473612008206, 50.9750708665479],
  //                       [-3.2547669349289166, 50.97334904486761],
  //                       [-3.2538235299685727, 50.97167778842196],
  //                       [-3.2526262318252748, 50.97007319232412],
  //                       [-3.251186571134653, 50.96855070971169],
  //                       [-3.2495184126161556, 50.96712500292449],
  //                       [-3.247637821548225, 50.96580980229825],
  //                       [-3.2455629090508804, 50.964617773933966],
  //                       [-3.2433136576657335, 50.963560397716414],
  //                       [-3.24091172891319, 50.96264785675659],
  //                       [-3.2383802546801643, 50.96188893932278],
  //                       [-3.235743614447365, 50.96129095420474],
  //                       [-3.2330272005015677, 50.96085966032606],
  //                       [-3.2302571733940137, 50.96059921128254],
  //                       [-3.22746021, 50.960512115340855],
  //                       [-3.224663246605986, 50.96059921128254],
  //                       [-3.221893219498432, 50.96085966032606],
  //                       [-3.219176805552635, 50.96129095420474],
  //                       [-3.2165401653198353, 50.96188893932278],
  //                       [-3.2140086910868098, 50.96264785675659],
  //                       [-3.211606762334266, 50.963560397716414],
  //                       [-3.2093575109491193, 50.964617773933966],
  //                       [-3.2072825984517745, 50.96580980229825],
  //                       [-3.205402007383844, 50.96712500292449],
  //                       [-3.2037338488653466, 50.96855070971169],
  //                       [-3.202294188174725, 50.97007319232412],
  //                       [-3.201096890031427, 50.97167778842196],
  //                       [-3.200153485071083, 50.97334904486761],
  //                       [-3.199473058799179, 50.9750708665479],
  //                       [-3.1990621640926724, 50.97682667137881],
  //                       [-3.198924758092204, 50.97859955],
  //                     ],
  //                   ],
  //                 },
  //               },
  //             ],
  //           },
  //         },
  //       },
  //       {
  //         marker: {
  //           coordinates: [15, 15.2],
  //           label: "airport",
  //           color: "#ff0000",
  //         },
  //       },
  //       // Custom marker
  //       {
  //         marker: {
  //           coordinates: [10, 11],
  //           url:
  //             "https://upload.wikimedia.org/wikipedia/commons/6/6f/0xff_timetracker.png",
  //         },
  //       },
  //     ],
  //   })
  //   .send()
  //   .then((response) => {
  //     const image = response.body;
  //   });

  // To get the URL instead of the image, create a request
  // and get its URL without sending it.
  const request = mapboxStaticClient.getStaticImage({
    ownerId: "mapbox",
    styleId: "streets-v11",
    width: 200,
    height: 300,
    position: {
      coordinates: [-3.22746021, 50.97859955],
      zoom: 8,
    },
    // overlays: [{ geoJson: radiusOverlay }],
  });
  const staticImageUrl = request.url();

  let workingDays = new Set(availabilities.map(({ node: day }) => day.name));

  const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
  const weekend = ["Saturday", "Sunday"];
  let days = [];

  weekdays.forEach((day) => {
    days.push({
      name: day,
      short: day.slice(0, 1),
      styles: dayStyle(true, workingDays.has(day)),
    });
  });
  weekend.forEach((day) => {
    days.push({
      name: day,
      short: day.slice(0, 1),
      styles: dayStyle(false, workingDays.has(day)),
    });
  });

  return (
    <div className={styles.availability}>
      <div>
        <p>I'm availabile to work on the following days:</p>
      </div>
      <div>
        <div className={styles.days}>
          {days.map((day, index) => {
            return (
              <div key={day.name} className={day.styles}>
                <span>{day.short}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.location}>
        <div className={styles.map}>
          <img src={staticImageUrl} />
        </div>
        <div className={styles.distance}>
          <h5>{workingAreaHelper(carer)}</h5>
          {carer.radiusNote ? `<span>${carer.radiusNote}</span>` : ``}
        </div>
      </div>
      <Button to="/contact" size="s" type="alt">
        Request a carer
      </Button>
    </div>
  );
};
