import React from "react";
import { Link, graphql } from "gatsby";
import SEO from "../components/seo";
import get from "lodash/get";
import Img from "gatsby-image";

import Layout from "../components/layout";
import Section from "../components/section";
import CarerAvailability from "../components/carer-availability";
import CarerServices from "../components/carer-services";

import styles from "./carer-page.module.scss";

class CarerTemplate extends React.Component {
  render() {
    const carer = get(this.props, "data.contentfulCaregiver");
    const carerServices = get(this.props, "data.allContentfulServices.edges");
    const carerWorking = get(this.props, "data.allContentfulWorkday.edges");
    const carerTestimonials = get(this.props, "data.allContentfulTestimonial");
    const siteTitle = get(this.props, "data.site.siteMetadata.title");

    const CarerTestimonial = ({ carer, testimonials }) => {
      const count = testimonials.totalCount;
      if (count >= 0) return null;
      const testimonial = testimonials.edges[0].node;

      return (
        <section>
          {/* TODO: update this to format correctly for "s's" */}
          <h3>Some words from {carer.fullName}'s clients</h3>
          <blockquote>{testimonial.preview}</blockquote>
          <cite>
            {testimonial.client}, {testimonial.createdAt}
          </cite>
          <button>
            Read all {carer.fullName}'s testimonials ({count})
          </button>
        </section>
      );
    };

    return (
      <Layout location={this.props.location}>
        <SEO
          title={carer.fullName}
          description={`Get to know ${carer.fullName} and learn about the services they offer as part of ${siteTitle}`}
          image={carer.carerImage.fixed.src}
        />
        <Section
          hero
          sectionClassName={styles.carer}
          innerClassName={styles.inner}
        >
          <div className={styles.breadcrumb}>
            <Link to="/carers">{"<"} See all carers</Link>
          </div>
          <div className={styles.info}>
            <Img
              className={styles.carerImage}
              alt={carer.fullName}
              fluid={carer.carerImage.fluid}
            />
            <div className={styles.member}>
              <h1>{carer.fullName}</h1>
              <span>Member since {carer.memberSince}</span>
              <p>{carer.about.about}</p>
            </div>
          </div>
        </Section>
        <Section sectionClassName={styles.details}>
          <div className={styles.services}>
            <h5>Services I offer</h5>
            <CarerServices carer={carer} services={carerServices} />
          </div>
          <div className={styles.availability}>
            <h5>My availability</h5>
            <CarerAvailability carer={carer} availabilities={carerWorking} />
          </div>
        </Section>

        <CarerTestimonial carer={carer} testimonials={carerTestimonials} />
        {/* 
        <Section>
          <div>
            <Link to="/">Home</Link>
          </div>
        </Section> */}
      </Layout>
    );
  }
}

export default CarerTemplate;

export const pageQuery = graphql`
  query CarerBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulCaregiver(slug: { eq: $slug }) {
      fullName
      memberSince(formatString: "MMMM Do, YYYY")
      attributes
      carerImage {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbyContentfulFluid_noBase64
        }
        fixed(cropFocus: TOP, width: 1200) {
          src
        }
      }
      about {
        about
      }
      servicesNote
      status
      memberRadius
      memberLocation
    }
    allContentfulServices(
      filter: { caregiver: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          id
          title
          specialist
        }
      }
    }
    allContentfulWorkday(
      filter: { caregiver: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          id
          name
          rateHourly
        }
      }
    }
    allContentfulTestimonial(
      filter: { caregiver: { elemMatch: { slug: { eq: $slug } } } }
      limit: 1
      sort: { order: ASC, fields: rank }
    ) {
      edges {
        node {
          id
          client
          createdAt(formatString: "MMM, YYYY")
          body {
            body
          }
          preview
        }
      }
      totalCount
    }
  }
`;
