import React from "react";

import styles from "../components/services.module.scss";

export default ({ carer, services }) => {
  return (
    <>
      <ul className={styles.list}>
        {" "}
        {services.map(({ node }) => {
          const { title, description } = node;
          return (
            <li key={title}>
              <p>{title}</p>
              {description ? <sub>{description.description}</sub> : ``}
            </li>
          );
        })}{" "}
      </ul>
      {carer.servicesNote ? (
        <div className={styles.note}>Please note: {carer.servicesNote}</div>
      ) : (
        ""
      )}
    </>
  );
};
